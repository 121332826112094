<template>
    <div class="alert alert-info">
        {{ $t('global.cheval_introuvable') }}. <router-link :to="{ name: 'horseListe' }">{{ $t('global.retourner_sur_liste') }}</router-link>
    </div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus';
    import Tools from "@/mixins/Tools.js";

	export default {
		mixins: [Tools],
		mounted(){
			this.init_component()
		},
		methods:{
			init_component() {
				EventBus.$emit('HeaderTab::updateHeaderTitle', this.getTrad("global.cheval_introuvable"))
			}
		}
	}
</script>
